<span class="star-rating">
  <!-- Full stars -->
  <ng-container *ngFor="let star of fullStars; let index = index">
    <i class="fas fa-star filled-star" (click)="setRating(index + 1)" [ngClass]="{ 'clickable': interactive }"></i>
  </ng-container>

  <!-- Half star -->
  <ng-container *ngIf="halfStar">
    <i class="fas fa-star-half-alt half-filled-star" (click)="setRating(fullStars.length + 0.5)"
      [ngClass]="{ 'clickable': interactive }"></i>
  </ng-container>

  <!-- Empty stars -->
  <ng-container *ngFor="let star of emptyStars; let index = index">
    <i class="far fa-star empty-star" (click)="setRating(fullStars.length + (halfStar ? 1 : 0) + index + 1)"
      [ngClass]="{ 'clickable': interactive }"></i>
  </ng-container>
</span>