import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css']
})
export class StarRatingComponent implements OnChanges {
  @Input() rating: number = 0;
  @Input() maxRating: number = 5;
  @Input() interactive: boolean = false;  // New input to control interactivity
  @Output() ratingChange = new EventEmitter<number>(); // Emit selected rating

  fullStars: number[] = [];
  halfStar: boolean = false;
  emptyStars: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateStars();
  }

  private calculateStars(): void {
    const fullStarsCount = Math.floor(this.rating);
    this.fullStars = Array(fullStarsCount).fill(0);

    const hasHalfStar = this.rating % 1 >= 0.5;
    this.halfStar = hasHalfStar;

    const emptyStarsCount = this.maxRating - fullStarsCount - (hasHalfStar ? 1 : 0);
    this.emptyStars = Array(emptyStarsCount).fill(0);
  }

  setRating(newRating: number): void {
    if (this.interactive) {
      this.rating = newRating;
      this.ratingChange.emit(this.rating);  // Emit the new rating value
      this.calculateStars(); // Update the star display
    }
  }
}
