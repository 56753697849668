// import { CommaSeparationPipe } from '../../pipes/comma-separation.pipe'
// import { FileSizeConverterPipe } from './../../pipes/file-size-converter.pipe'
// import { SafeHtmlPipe } from './../../pipes/safe-html.pipe'
// import { SuffixPipe } from './../../pipes/suffix.pipe'
import { LoaderComponent } from './loader/loader.component'
import { IRatingModule } from 'src/app/libs/irating/irating.module'
import { IAlertsModule } from 'src/app/libs/ialert/ialerts.module'

import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { ResponseComponent } from './response/response.component'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SmartClickDirective } from 'src/app/directives/smart-click.directive'
import { ReadMoreComponent } from 'src/app/directives/read-more.component'

// import { FilterPipe } from 'src/app/pipes/filter.pipe'
import { CgEditorModule } from 'src/app/libs/cg-editor/cg-editor.module'
import { TruncatePipe } from 'src/app/pipes/truncate.pipe'
import { FormatDatePipe } from 'src/app/pipes/format-date.pipe'
import { FormatTimePipe } from 'src/app/pipes/format-time.pipe'
import { StarRatingComponent } from './star-rating/star-rating.component'
import { FilterPipe } from 'src/app/pipes/filter.pipe'

@NgModule({
    imports: [
        CommonModule,
        IAlertsModule,
        IRatingModule,
        CgEditorModule
    ],
    declarations: [
        ResponseComponent,
        PageNotFoundComponent,
        SmartClickDirective,
        ReadMoreComponent,
         FilterPipe,
        // SafeHtmlPipe,
        LoaderComponent,
        // SuffixPipe,
        // CommaSeparationPipe,
        // FileSizeConverterPipe,
        TruncatePipe,
        FormatDatePipe,
        FormatTimePipe,
        StarRatingComponent
    ],
    exports: [
         FilterPipe, //SafeHtmlPipe, 
        CgEditorModule, ReadMoreComponent, IAlertsModule, IRatingModule,
        // CommaSeparationPipe,
        CommonModule,
        // SuffixPipe, 
        LoaderComponent, ResponseComponent, PageNotFoundComponent, SmartClickDirective,
        // FileSizeConverterPipe,
        TruncatePipe, FormatDatePipe,
        FormatTimePipe, StarRatingComponent
    ]
})
export class SharedModule { }
