import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ThemeService } from './services/theme.service'
import { Observable } from 'rxjs'
import { MessagingService } from './services/messaging.service'
import { ApiService } from './services/api.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    isLoading: boolean

    constructor(
        public route: Router,
        public router: ActivatedRoute,
        private api: ApiService,
        private themeService: ThemeService,
        public messaging: MessagingService
    ) {
        this.route.events.subscribe((routerEvent: Event) => {})
    }

    ngOnInit() {
        this.themeService.setTheme('default')
        this.messaging.requestPermission()
        this.messaging.receiveMessage()
        this.messaging.recieveMessageNotification()

        this.route.events.subscribe((routerEvent: Event) => {
            if (routerEvent instanceof NavigationEnd) {
                window.scrollTo(0, 0)
            }
        })
     
    }
}
