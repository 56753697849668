import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConstantsService } from './constants.service';
import { map } from 'rxjs/operators';
import { apis } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { User } from '../models/user';
import { IAlertService } from '../libs/ialert/ialerts.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = `${apis.baseUrl}`;
  dataStatusSubscribe = 'fetching';
  userLoggedInSource = new BehaviorSubject(false);
  scrollBottom: boolean;
  scrollBottomChange = new Subject<boolean>();
  userImage = new Subject<string>();
  notification = new Subject<string>();
  userLoggedInObs = this.userLoggedInSource.asObservable();
  user: User;
  categoriesList: any = [];
  subsPackageList: any = [];
  constructor(
    public http: HttpClient,
    public cs: ConstantsService,
    private ts: TranslateService,
    public alert: IAlertService,
    private router: Router
  ) {
    this.baseUrl = apis.baseUrl;
    this.scrollBottom = false;
    this.scrollBottomChange.subscribe((value) => {
      this.scrollBottom = value;
    });
    if (localStorage.getItem('token')) {
      this.user = JSON.parse(localStorage.getItem('user') as '');
      this.userLoggedInSource.next(true);
    } else {
      this.userLoggedInSource.next(false);
    }
  }

  toggleScrollBottom(value: boolean): void {
    this.scrollBottomChange.next(value);
  }

  login(params: any): Observable<any> {
    const url = `${this.baseUrl}/public/login`;
    return this.http.post<any>(url, params).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          this.userLoggedInSource.next(true);
        }
        return resp;
      })
    );
  }

  googleLogin(): Observable<any> {
    const url = `${this.baseUrl}/login/${'google'}`;

    return this.http.get<any>(url).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          // console.log(this.user)
          this.userLoggedInSource.next(true);
        }

        return resp;
      })
    );
  }

  signup(params: any): Observable<any> {
    const url = `${this.baseUrl}/signup`;

    return this.http.post<any>(url, params).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          this.userLoggedInSource.next(true);
        }

        return resp;
      })
    );
  }

  doUserRedirects(resp: any, router: Router, url?: any) {
    switch (resp.data.userType) {
      case ConstantsService.USER_ROLES.SUPERADMIN || 'super-admin': {
        router.navigate(['/superadmin/dashboard']);
        break;
      }
      case ConstantsService.USER_ROLES.ADMIN || 'admin': {
        router.navigate(['/admin/dashboard']);
        break;
      }
      case ConstantsService.USER_ROLES.MEMBER || 'member': {
        this.user.hasSubscription
          ? router.navigate(['/member/dashboard'])
          : router.navigate(['/dashboard']);
        break;
      }
      case ConstantsService.USER_ROLES.SELLER || 'seller': {
        router.navigate(['/sales-person/dashboard']);
        break;
      }
    }
  }

  logOut(): boolean {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.user.id = 0;
    this.userLoggedInSource.next(false);
    this.router.navigate(['/login']);
    return true;
  }

  logOutSession(): Observable<any> {
    const url = `${this.baseUrl}/logout`;
    return this.http.post<any>(url, {});
  }

  isAuthenticated(): boolean {
    if (localStorage.getItem('token')) {
      return true;
    }
    return false;
  }
  isLogin(): boolean {
    if (!localStorage.getItem('token')) {
      return false;
    }

    return true;
  }

  isSuperAdmin(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.userType === ConstantsService.SUPERADMIN
    ) {
      return true;
    }

    return false;
  }

  isAdmin(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.userType === ConstantsService.ADMIN
    ) {
      return true;
    }

    return false;
  }

  isMember(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.userType === ConstantsService.MEMBER
    ) {
      return true;
    }
    return false;
  }

  isSeller(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.userType === ConstantsService.SELLER
    ) {
      return true;
    }

    return false;
  }

  jsonToFormData(
    jsonObject: any,
    parentKey?: any,
    carryFormData?: FormData
  ): FormData {
    const formData = carryFormData || new FormData();
    let index = 0;

    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
          let propName = parentKey || key;
          if (parentKey && this.isObject(jsonObject)) {
            propName = parentKey + '[' + key + ']';
          }
          if (parentKey && this.isArray(jsonObject)) {
            propName = parentKey + '[' + index + ']';
          }
          if (jsonObject[key] instanceof File) {
            formData.append(propName, jsonObject[key]);
          } else if (jsonObject[key] instanceof FileList) {
            for (let j = 0; j < jsonObject[key].length; j++) {
              formData.append(
                propName + '[' + j + ']',
                jsonObject[key].item(j)
              );
            }
          } else if (
            this.isArray(jsonObject[key]) ||
            this.isObject(jsonObject[key])
          ) {
            this.jsonToFormData(jsonObject[key], propName, formData);
          } else if (typeof jsonObject[key] === 'boolean') {
            formData.append(propName, +jsonObject[key] ? '1' : '0');
          } else {
            formData.append(propName, jsonObject[key]);
          }
        }
      }
      index++;
    }

    return formData;
  }

  updateUser<K extends keyof User>(key: K, newValue: User[K]) {
    // const updatedUser: User = { ...this.user };
    const updatedUser: User = { ...this.user, [key]: newValue };
    this.user = updatedUser;
    updatedUser[key] = newValue;
    localStorage.setItem('user', JSON.stringify(updatedUser));
  }

  isArray(val: any) {
    const toString = {}.toString;

    return toString.call(val) === '[object Array]';
  }

  isObject(val: any) {
    return !this.isArray(val) && typeof val === 'object' && !!val;
  }

  checkVerificationCode(data: any): Observable<any> {
    const url = `${apis.baseUrl}/public/verify-email`;
    return this.http.post<any>(url, data);
  }

  resendVerificationCode(data: any): Observable<any> {
    const url = `${apis.baseUrl}/public/resend-code`;
    return this.http.post<any>(url, data);
  }

  checkVerifyCode(data: any): Observable<any> {
    const url = `${this.baseUrl}/public/verify-code`;
    return this.http.post<any>(url, data);
  }

  verifyResetCode(data: any): Observable<any> {
    const url = `${this.baseUrl}/public/verify-reset-code`;
    return this.http.post<any>(url, data);
  }

  resendResetCode(data: any): Observable<any> {
    const url = `${apis.baseUrl}/public/resend-reset-code`;
    return this.http.post<any>(url, data);
  }

  resetPass(data: any): Observable<any> {
    const url = `${this.baseUrl}/public/reset-password`;
    return this.http.post<any>(url, data);
  }

  translate(key: string) {
    return this.ts.get(key);
  }

  getCurrentLang() {
    let lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : this.ts.getBrowserLang();
    if (!lang) {
      lang = 'en';
    }
    return lang;
  }

  changeLanguage(lang: string) {
    this.ts.currentLang = lang;
    localStorage.setItem('lang', lang);
    this.ts.setDefaultLang(lang);
    this.ts.use(lang);
    location.reload();
  }

  userImageUrl(userId?: number) {
    userId = userId ? userId : -1;
    return `${apis.baseUrl}/public/profile-image/${userId}`;
  }

  checkPermission(permission: string): boolean {
    const checkUrserRole = this.user.user_roles.findIndex(
      (r) => r.role.name === ConstantsService.SUPERADMIN
    );

    if (checkUrserRole > -1) {
      return true;
    }

    /*const index = this.user.UserPermissions.findIndex(
      (item: any) => item.Permission.title === permission
    );*/

    const index = this.user.permissions.findIndex(
      (item: any) => item.name === permission
    );

    // console.log('asdsad',index,permission,this.user.permissions[index]);

    if (index > -1) {
      return true;
    }

    return false;
  }

  checkPermissions(permissions: Array<string>): boolean {
    if (permissions[0] == '' || permissions[0] == undefined) {
      return true;
    }
    // Check if user has any roles with the specified permissions
    if (this.user.user_roles && this.user.user_roles.length > 0) {
      for (let role of this.user.user_roles) {
        if (role.role && role.role.permissions && role.role.permissions.length > 0) {
          for (let permission of permissions) {
            if (role.role.permissions.some(p => p.name === permission)) {
              return true; // User has the permission
            }
          }
        }
      }
    }

    // Check if user has direct permissions
    if (this.user.user_permissions && this.user.user_permissions.length > 0) {
      for (let userPermission of this.user.user_permissions) {
        if (permissions.includes(userPermission.permission.name)) {
          return true; // User has the permission
        }
      }
    }

    return false; // User does not have any of the specified permissions
  }


  checkRole() {
    return true;
    // const checkUrserRole = this.user.userroles.findIndex(r => r.role.name === ConstantsService.ADMIN)

    // if (checkUrserRole > -1) {
    //     return true
    // }

    // return false
  }
}
